//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import {
  onChangeCompanyBusiness,
  mappingCurrency,
  mappingThousand
} from '@/utils/helpers.js'

export default {
  name: 'MainSidebar',
  filters: {
    mappingCurrency,
    mappingThousand
  },
  props: {
    isVerifyBannerShown: {
      type: Boolean,
      default: false
    },
    isAnnouncementShown: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const mainMenu = [
      {
        name: 'Home',
        icon: 'home-sclvico',
        link: '/dashboard',
        hasPermission: true,
        children: [
          {
            name: 'Insights',
            link: '/insights',
            hasPermission: false
          }
        ],
        isExpanded: false
      },
      {
        name: 'Products',
        icon: 'ic-cube',
        link: '/products',
        hasPermission: false,
        children: [
          {
            name: 'Bundles',
            link: '/bundles',
            hasPermission: false
          },
          {
            name: 'Inventory',
            link: '/inventory',
            hasPermission: false
          }
        ],
        isExpanded: false
      },
      {
        name: 'Landing Pages',
        icon: 'doc-sclvico',
        link: '/pages',
        hasPermission: false,
        children: [],
        isExpanded: false
      },
      {
        name: 'Orders',
        icon: 'ic-orders',
        link: '/order',
        hasPermission: false,
        children: [
          {
            name: 'Ongkir',
            link: '/shipping-fee',
            hasPermission: true
          }
        ],
        isExpanded: false
      },
      {
        name: 'Advertising',
        icon: 'ic-ads',
        link: '/advertising',
        hasPermission: false,
        children: [
          // {
          //   name: 'View',
          //   link: '/advertising/view',
          //   hasPermission: true
          // },
          {
            name: 'Ads Manager',
            link: '/advertising/ads-manager',
            hasPermission: false
          }
        ],
        isExpanded: false
      },
      {
        name: 'Stores',
        icon: 'ic-store',
        link: '/store',
        hasPermission: false,
        children: [],
        isExpanded: false
      },
      {
        name: 'Customers',
        icon: 'user-group-sclvico',
        link: '/customer',
        hasPermission: false,
        children: [],
        isExpanded: false
      },
      {
        name: 'Developers',
        icon: 'developer',
        link: '/developers',
        hasPermission: false,
        children: [],
        isExpanded: false
      },
      {
        name: 'Tutorials',
        icon: 'information-sclvico',
        link: '/tutorial',
        hasPermission: true,
        children: [],
        isExpanded: false
      }
    ]
    return {
      isCancel: false,
      mainMenu,
      breakpointWatcher: null,
      canViewBalance: false,
      businessCountAlert: {
        check: false,
        maxBusiness: 1,
        ownedBusinessCount: 0
      },
      isLoadingBalance: true,
      canEditBusiness: false,
      isWarningsShown: false,
      balances: [],
      isAnotherBalancesShown: false
    }
  },
  async fetch() {
    if (
      this.$auth?.user?.currentBusiness?.invitationStatus !== 'invited'
      // this.$auth?.user?.currentBusiness?.isUserBusinessActive
    ) {
      this.canViewBalance = await this.$store.dispatch(
        'permission/hasPermission',
        { user: this.$auth.user, permission: ['view_business_transaction'] }
      )
    }
  },
  fetchKey: 'dashboard-sidebar',
  computed: {
    ...mapGetters('xpTransaction', ['getBalance', 'getHoldingBalance']),
    ...mapGetters('subscription', [
      'getDetailSubscription',
      'getOpenSubscriptionOrder'
    ]),
    isWarningsAvailable() {
      return (
        (this.getDetailSubscription?.current_pricing_plan?.subscription_plan
          ?.monthly_order_limit &&
          this.orderLimitUsage >= 80) ||
        this.getOpenSubscriptionOrder ||
        this.getDetailSubscription?.status === 'unpaid'
      )
    },
    orderLimitUsage() {
      let percent = 0

      if (
        this.getDetailSubscription?.current_pricing_plan?.subscription_plan
          ?.monthly_order_limit
      ) {
        percent = Math.round(
          (this.$auth.user.currentBusiness.monthly_order_count /
            this.getDetailSubscription?.current_pricing_plan.subscription_plan
              .monthly_order_limit) *
            100
        )
      }

      return percent
    },
    mini: {
      get() {
        return this.$store.getters['common/getSidebarMini']
      },
      set(val) {
        this.$store.dispatch('common/setSidebarMini', val)
      }
    },
    isBusinessAddModelShow: {
      get() {
        return this.getBusinessAddModalShow
      },
      set(val) {
        this.$store.dispatch('common/setBusinessAddModalShow', val)
      }
    },
    ...mapGetters('notification', ['getUnread']),
    ...mapGetters('common', ['getBusinessAddModalShow', 'getSidebarKey']),
    ...mapGetters('balance', ['getCurrentBalance', 'getOnHoldBalance']),
    getIsOwner() {
      return (
        (this.$auth.user.currentBusiness || {}).owner?.id ===
        this.$auth.user.detail?.id
      )
    }
  },
  watch: {
    getSidebarKey() {
      this.$fetch()
    }
  },
  async mounted() {
    this.$store.dispatch(
      'subscription/putSubscription',
      this.$auth.user.detail.current_business_subscription
    )

    const [
      canEditBusiness,
      canViewAnalytics,
      canViewProducts,
      canViewBundle,
      canViewInventoryBalance,
      canViewOrderList,
      canViewStoreList,
      canViewWebStudio,
      canViewCusList,
      canViewDevelopers,
      canViewCampaigns
    ] = await Promise.all([
      this.$store.dispatch('permission/hasPermission', {
        user: this.$auth.user,
        permission: 'edit_business'
      }),
      this.$store.dispatch('permission/hasPermission', {
        user: this.$auth.user,
        permission: 'view_order'
      }),
      this.$store.dispatch('permission/hasPermission', {
        user: this.$auth.user,
        permission: 'view_product'
      }),
      this.$store.dispatch('permission/hasPermission', {
        user: this.$auth.user,
        permission: 'view_bundle'
      }),
      this.$store.dispatch('permission/hasPermission', {
        user: this.$auth.user,
        permission: 'view_inventory_flow'
      }),
      this.$store.dispatch('permission/hasPermission', {
        user: this.$auth.user,
        permission: 'view_order'
      }),
      this.$store.dispatch('permission/hasPermission', {
        user: this.$auth.user,
        permission: 'list_store'
      }),
      this.$store.dispatch('permission/hasPermission', {
        user: this.$auth.user,
        permission: ['view_form', 'view_page']
      }),
      this.$store.dispatch('permission/hasPermission', {
        user: this.$auth.user,
        permission: 'view_customer'
      }),
      this.$store.dispatch('permission/hasPermission', {
        user: this.$auth.user,
        permission: 'manage_developer_settings'
      }),
      this.$store.dispatch('permission/hasPermission', {
        user: this.$auth.user,
        permission: 'view_campaigns'
      })
    ])

    if (this.canViewBalance) {
      try {
        await Promise.all([
          this.$store.dispatch('xpTransaction/fetchBalance'),
          this.$store.dispatch('xpTransaction/fetchHoldingBalance'),
          this.$store.dispatch('balance/fetchBalance')
        ])
      } catch {}
    }

    this.canEditBusiness = canEditBusiness

    this.mainMenu.forEach((menuList, i) => {
      if (menuList.name === 'Products') {
        this.mainMenu[i].hasPermission = canViewProducts
      }

      if (menuList.name === 'Landing Pages') {
        menuList.hasPermission = canViewWebStudio
      }

      if (menuList.name === 'Orders') {
        this.mainMenu[i].hasPermission = canViewOrderList
      }

      if (menuList.name === 'Advertising') {
        this.mainMenu[i].hasPermission = canViewCampaigns 
      }

      if (menuList.name === 'Stores') {
        this.mainMenu[i].hasPermission = canViewStoreList
      }

      if (menuList.name === 'Customers') {
        this.mainMenu[i].hasPermission = canViewCusList
      }

      if (menuList.name === 'Developers') {
        this.mainMenu[i].hasPermission =
          canViewDevelopers &&
          (this.$auth.user?.account?.sidebar_menus || []).includes('developers')
      }

      menuList.children.forEach((child, j) => {
        if (child.name === 'Bundles') {
          this.mainMenu[i].children[j].hasPermission = canViewBundle
        }

        if (child.name === 'Inventory') {
          this.mainMenu[i].children[j].hasPermission = canViewInventoryBalance
        }

        if (child.name === 'Insights') {
          this.mainMenu[i].children[j].hasPermission = canViewAnalytics
        }

        if (child.name === 'Ads Manager') {
          this.mainMenu[i].children[j].hasPermission = canViewCampaigns 
        }
      })
    })

    this.mainMenu = this.mainMenu.map((x) => {
      return {
        ...x,
        isExpanded:
          x.children
            .filter((y) => y.hasPermission)
            .some((y) => this.$route.path.startsWith(y.link)) ||
          (x.children.filter((y) => y.hasPermission).length > 0 &&
            this.$route.path.startsWith(x.link))
      }
    })

    if (this.$mq === 'md' || this.$mq === 'sm') {
      this.mini = true
    } else {
      this.mini = false
    }
    this.breakpointWatcher = this.$watch('$mq', (val) => {
      if (val === 'md' || val === 'sm') {
        this.mini = true
      } else {
        this.mini = false
      }
    })
    document.addEventListener('showWarnings', () => {
      this.isWarningsShown = true
    })

    if (this.$auth.user.currentBusiness?.is_epayment_enabled) {
      this.balances.push({
        name: 'Classic Balance',
        balance:
          parseInt(this.getCurrentBalance) + parseInt(this.getOnHoldBalance)
      })
    }

    this.isLoadingBalance = false
  },
  methods: {
    async maybeReactivateSubscription() {
      this.$nuxt.$loading.start(true)

      if (this.getOpenSubscriptionOrder) {
        window.location.href = await this.reminderTargetUrl()
        return
      }

      try {
        if (this.getDetailSubscription == null) {
          window.location.href = `/setting/billing?isPricePlanShown=true`
          return
        }

        const pricingPlanCode =
          this.getDetailSubscription?.current_pricing_plan?.code
        const payloadPayment = {
          idSubscription: this.getDetailSubscription?.id,
          pricingPlanCode
        }
        await this.$store.dispatch(
          'subscription/partialUpdateSubscription',
          payloadPayment
        )

        window.location.href = await this.reminderTargetUrl()
      } catch (error) {
        this.$errorHandler(error)
        this.$nuxt.$loading.finish()
      }
    },
    async executeReminderCallToAction() {
      this.$nuxt.$loading.start(true)
      window.location.href = await this.reminderTargetUrl()
    },
    async reminderTargetUrl() {
      if (this.getOpenSubscriptionOrder == null) {
        return `/setting/billing?isPricePlanShown=true`
      }

      try {
        await Promise.all([
          this.$store.dispatch('xpTransaction/fetchBalance'),
          this.$store.dispatch('xpTransaction/fetchHoldingBalance')
        ])
      } catch {}
      if (this.getBalance >= this.getOpenSubscriptionOrder.amount) {
        return `/setting/billing?subscriptionOrderId=${this.getOpenSubscriptionOrder.id}`
      } else {
        return this.getOpenSubscriptionOrder.xendit_invoice_url
      }
    },
    expandMenu(index) {
      this.mainMenu = this.mainMenu.map((x) => ({
        ...x,
        isExpanded: false
      }))

      if (
        this.mainMenu[index]?.children.filter((x) => x.hasPermission).length > 0
      ) {
        this.mainMenu[index].isExpanded = true
      }
    },
    onChangeCompanyBusiness,
    daysDifference(dateString) {
      const inputDate = new Date(dateString)
      const today = new Date()

      const differenceInTime = inputDate - today

      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 60 * 60 * 24)
      )

      return differenceInDays < 0 ? 0 : differenceInDays
    },
    onAddClick() {
      this.isBusinessAddModelShow = true
      this.$emit('add-business')
    },
    yesCancel() {
      this.isCancel = false
      this.isBusinessAddModelShow = false
    },
    noCancel() {
      this.isCancel = false
    },
    callScalev() {
      this.businessCountAlert.check = false
      open('https://t.me/scalev_id', '_blank')
    }
  }
}
